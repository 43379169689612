<template>
  <div class="cart pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      showNavbar
      showSearch
    >
    </NavbarOne>
    <!-- </section> -->
    <v-card class="card-top ma-3 mt-16 ma-md-5">
      <custom-section class="section">
        <v-row ref="form">
          <v-col cols="12" lg="8">
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div class="mb-5">
                <heading-card
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.customer_information')"
                />
              </div>
              <v-row v-if="profile != null">
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.first_name"
                    :label="$t('field.first_name')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.last_name"
                    :label="$t('field.last_name')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.email"
                    :label="$t('field.email')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.phone"
                    :label="$t('field.phone')"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <div>
                <v-checkbox v-model="notForMe" dense hide-details>
                  <template v-slot:label>
                    <div class="checkbox--booking-else">
                      {{ $t("page.cart.tick_booking_other") }}
                    </div>
                  </template>
                </v-checkbox>
              </div> -->
              <div v-if="notForMe">
                <v-row class="my-1">
                  <v-col cols="6">
                    <v-autocomplete
                      ref="family_id"
                      item-text="related_person_name"
                      item-value="patient_id"
                      :label="$t('field.name')"
                      :items="myFamily"
                      v-model="payload.family_id"
                      outlined
                      :rules="[
                        v => !!v || `${$t('field.name')} ${$t('is_required')}`,
                      ]"
                      dense
                      :hide-details="!formHasErrors"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- <p class="register-text">
                  {{ $t("page.cart.not_register_yet") }}
                  <span @click="openRegister()">{{ $t("here") }} </span>
                </p> -->
                <!-- detail order -->
                <v-row
                  v-if="payload.family_id != '' && payload.family_id != null"
                >
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payloadForOther.related_person_first_name"
                      :label="$t('field.first_name')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payloadForOther.related_person_last_name"
                      :label="$t('field.last_name')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payloadForOther.related_person_id_number"
                      :label="$t('field.id_number')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :value="formatDate(payloadForOther.related_date_of_birth)"
                      :label="$t('field.birth_date')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="
                      payloadForOther.related_person_email != null &&
                      payloadForOther.related_person_email != ''
                    "
                  >
                    <v-text-field
                      v-model="payloadForOther.related_person_email"
                      :label="$t('field.email')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    v-if="
                      payloadForOther.related_person_phone != null &&
                      payloadForOther.related_person_phone != ''
                    "
                  >
                    <v-text-field
                      v-model="payloadForOther.related_person_phone"
                      :label="$t('field.phone')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payloadForOther.related_person_gender"
                      :label="$t('field.gender')"
                      outlined
                      hide-details
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- form sub menu -->
                <v-row
                  v-if="
                    (payload.family_id == '' || payload.family_id == null) &&
                    openFormRegister
                  "
                >
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mt-0 uppercase-input"
                      ref="regster_first_name"
                      v-model="payloadForOther.firstname"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.first_name')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.first_name')"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      dense
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mt-0 uppercase-input"
                      ref="regster_last_name"
                      v-model="payloadForOther.lastname"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.last_name')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.last_name')"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      class="mt-0"
                      ref="regster_identity_type"
                      v-model="payloadForOther.identityType"
                      :items="identityType"
                      :item-text="
                        $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                      "
                      item-value="id"
                      readonly
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.identity_type')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.identity_type')"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      dense
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      class="mt-0"
                      ref="regster_identity_number"
                      v-model="payloadForOther.idcard"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.identity_number')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.identity_number')"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      class="mt-0"
                      ref="regster_identity_country"
                      v-model="payloadForOther.identityCountry"
                      :items="identityCountry"
                      item-text="name"
                      item-value="id"
                      :rules="[
                        v =>
                          !!v ||
                          `${$t('field.identity_country_isu')} ${$t(
                            'is_required'
                          )}`,
                      ]"
                      :label="$t('field.identity_country_isu')"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      dense
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="opendatepickerBirthDate"
                      :close-on-content-click="false"
                      max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="birthDateFormatted"
                          ref="register_birth_date"
                          outlined
                          :hide-details="!formRegisterHasErrors"
                          append-icon="mdi-calendar-range"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            v =>
                              !!v ||
                              `${$t('filed.book_date')} ${$t('is_required')}`,
                          ]"
                          :label="$t('field.birth_date')"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :locale="$i18n.locale"
                        v-model="payloadForOther.birthDate"
                        @change="opendatepickerBirthDate = false"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        color="#00a4b3"
                        header-color="#00a4b3"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      class="mt-0"
                      ref="regster_gender"
                      v-model="payloadForOther.selectGender"
                      :items="gender"
                      :item-text="
                        $i18n.locale == 'en' ? `name` : `name_${$i18n.locale}`
                      "
                      item-value="id"
                      :rules="[
                        v =>
                          !!v || `${$t('field.gender')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.gender')"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      dense
                      required
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select
                      dense
                      ref="regster_relation"
                      :items="relationItem"
                      v-model="payloadForOther.relation"
                      :item-text="`name_${$i18n.locale}`"
                      item-value="id"
                      outlined
                      :hide-details="!formRegisterHasErrors"
                      :rules="[
                        v =>
                          !!v || `${$t('field.relation')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.relation')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <c-button rounded outline @click="openFormRegister = false">
                      {{ $t("cancel") }}
                    </c-button>
                    <c-button class="ml-3" rounded @click="register">
                      {{ $t("add") }}
                    </c-button>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-card class="card pa-3 pa-md-5" outlined elevation="1">
              <div>
                <heading-card
                  bold
                  color="dark-blue"
                  class="mb-5"
                  :title="$t('page.cart.branch_appointment')"
                />
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="branch"
                    ref="branch"
                    :label="$t('field.branch')"
                    item-text="name"
                    item-value="id"
                    v-model="payload.branch"
                    dense
                    outlined
                    :hide-details="!formHasErrors"
                    :rules="[
                      v => !!v || `${$t('field.branch')} ${$t('is_required')}`,
                    ]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="opendatepicker"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        ref="booking_date"
                        :label="$t('field.booking_date')"
                        outlined
                        :hide-details="!formHasErrors"
                        append-icon="mdi-calendar-range"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          v =>
                            !!v ||
                            `${$t('field.booking_date')} ${$t('is_required')}`,
                        ]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="payload.booking_date"
                      @change="opendatepicker = false"
                      :min="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      color="#00a4b3"
                      header-color="#00a4b3"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    ref="note"
                    v-model="payload.note"
                    outlined
                    rows="2"
                    hide-details
                    :label="$t('page.cart.order_comment')"
                    :placeholder="`${$t('type_here')}...`"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4">
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
            >
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.order }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.order_review')"
                />
                <div @click="collapse.order = !collapse.order">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.order }"
                  />
                </div>
              </div>
              <template v-if="!collapse.order">
                <card-cart-item
                  v-if="servicePackage != null"
                  :name="servicePackage.name"
                  :price="`IDR ${formatPrice(
                    getInvoiceById(servicePackage.id).final_price
                  )}`"
                  :realPrice="`${
                    getInvoiceById(servicePackage.id).item_applied == 1
                      ? `IDR ${formatPrice(
                          getInvoiceById(servicePackage.id).price_total
                        )}`
                      : ''
                  }`"
                  hideClose
                />
              </template>
            </v-card>
            <v-card
              class="card pa-3 mb-3 pa-md-5 mb-md-5"
              outlined
              elevation="1"
              v-if="listDiscount != null && listDiscount.length > 0"
            >
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.voucher }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.vouchers')"
                />
                <div @click="collapse.voucher = !collapse.voucher">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.voucher }"
                  />
                </div>
              </div>
              <template v-if="!collapse.voucher && listDiscount != null">
                <template v-if="listDiscount.length > 0">
                  <voucher
                    v-for="(item, index) of listDiscount"
                    :key="index"
                    :discount="item.name"
                    :desc="`Up to IDR ${formatPrice(item.amount_max)}`"
                    :valid="`${$t('page.cart.valid_until')} ${formatDate(
                      item.end_date
                    )}`"
                    :tnc="item.terms_and_conditions"
                    class="voucher"
                    :active="checkVoucherActive(item.code)"
                    @toggleVoucher="toggleVoucher(item.code)"
                  />
                </template>
                <template v-else>
                  <p class="mb-0 count-item">
                    {{ $t("page.cart.voucher_not_available") }}
                  </p>
                </template>
              </template>
            </v-card>
            <v-card class="card pa-3 pa-md-5" outlined elevation="1">
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.payment }"
                  bold
                  color="dark-blue"
                  :title="$t('page.cart.billing_summary')"
                />
                <div @click="collapse.payment = !collapse.payment">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.payment }"
                  />
                </div>
              </div>
              <template v-if="!collapse.payment">
                <div class="billing-box">
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      :text="$t('page.cart.subtotal')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR ${formatPrice(this.subTotal)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      :text="$t('page.cart.voucher')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`- IDR ${formatPrice(this.totalDiscount)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <!-- <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      :text="$t('page.cart.tax')"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR ${formatPrice(this.tax)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div> -->
                </div>
              </template>
              <div class="d-flex justify-space-between mt-3 mb-2">
                <heading-price
                  extraBold
                  :text="$t('page.cart.grand_total')"
                  position="left"
                  color="main"
                />
                <heading-price
                  :text="`IDR ${formatPrice(this.totalPayment)}`"
                  position="right"
                  color="main"
                  extraBold
                />
              </div>
              <div class="checkbox--booking-else mb-3">
                {{ $t("page.cart.by_clic_pay") }}
                <tnc />
              </div>

              <c-button
                bold
                shadow
                rounded
                fluid
                @click="payment"
                type="submit"
                :disabled="!show"
              >
                {{ $t("pay") }} IDR
                {{ formatPrice(this.totalPayment) }}</c-button
              >
            </v-card>
          </v-col>
        </v-row>
      </custom-section>
      <v-dialog v-model="dialogConfirmation" persistent max-width="290">
        <v-card class="pa-3">
          <div class="d-flex justify-center mb-3">
            <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
          </div>
          <p class="text-center">
            {{ $t("coming_soon_package") }}
          </p>

          <div class="d-flex justify-center">
            <c-button
              class="mx-2 py-2"
              rounded
              outline
              @click="
                () => {
                  dialogConfirmation = false;
                  setBranchBack();
                }
              "
              >{{ $t("ok") }}</c-button
            >
          </div>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Icons from "@/components/elements/Icons.vue";
import CardCartItem from "@/components/v2/card/card_cart_item.vue";
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import CButton from "@/components/v2/button/index.vue";
import Voucher from "@/components/v2/voucher/index.vue";
import Tnc from "@/components/v2/tnc/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  metaInfo() {
    return {
      script: [
        {
          src: process.env.VUE_APP_MIDTRANS_URL,
          "data-client-key": process.env.VUE_APP_MIDTRANS_CLIENT_KEY,
          defer: true,
          callback: () => this.$store.commit("loadMidtrans"),
        },
      ],
    };
  },
  components: {
    CustomSection,
    HeadingCard,
    Icons,
    CardCartItem,
    HeadingPrice,
    CButton,
    Voucher,
    Tnc,
    NavbarOne,
  },
  data: () => ({
    branch: [],
    profile: null,
    mypackage: null,
    checkbox: false,
    payload: {
      booking_date: "",
      branch: "",
      note: "",
      family_id: "",
    },
    show: true,
    collapse: {
      order: false,
      payment: false,
      voucher: false,
    },
    listDiscount: null,
    activeVoucher: [],
    invoice: null,
    subTotal: 0,
    totalPayment: 0,
    totalDiscount: 0,
    tax: 0,
    opendatepicker: false,
    opendatepickerBirthDate: false,
    formHasErrors: false,
    formRegisterHasErrors: false,
    errorMessages: "",
    midtransStatus: 406,
    servicePackage: null,
    payloadForOther: {
      idcard: "",
      firstname: "",
      lastname: "",
      birthDate: "",
      birthPlace: "",
      identityType: 128,
      identityCountry: 102,
      relation: "",
      selectGender: "",
    },
    notForMe: false,
    myFamily: [],
    openFormRegister: false,
    gender: [],
    identityType: [],
    identityCountry: [],
    dialogConfirmation: false,
    vouchertrigger: "",
  }),
  watch: {
    payload: {
      handler() {
        this.show = true;
      },
      deep: true,
    },
    "payload.branch": function () {
      this.getPackage();
    },
    notForMe: function (val) {
      if (val) {
        this.getfamily();
      }
    },
    "payload.family_id": function (val) {
      if (val) {
        const indexFamily = this.myFamily.findIndex(
          item => item.patient_id == val
        );
        if (indexFamily > -1) {
          this.payloadForOther = this.myFamily[indexFamily];
        }
      }
    },
  },
  computed: {
    dateFormatted() {
      return this.formatDate(this.payload.booking_date);
    },

    birthDateFormatted() {
      return this.payloadForOther.birthDate == ""
        ? ""
        : this.formatDate(this.payloadForOther.birthDate);
    },
    form() {
      return {
        booking_date: this.payload.booking_date,
        branch: this.payload.branch,
        family_id: this.payload.family_id,
      };
    },
    formRegister() {
      return {
        regster_first_name: this.payloadForOther.firstname,
        regster_last_name: this.payloadForOther.lastname,
        regster_identity_type: this.payloadForOther.identityType,
        regster_identity_number: this.payloadForOther.idcard,
        regster_identity_country: this.payloadForOther.identityCountry,
        register_birth_date: this.payloadForOther.birthDate,
        regster_gender: this.payloadForOther.selectGender,
        regster_relation: this.payloadForOther.relation,
      };
    },
  },
  created() {
    if (this.$route.query.transaction_status) {
      if (this.$route.query.transaction_status == "pending") {
        this.$router.push(
          `/${this.$i18n.locale}/payment-pending-packaege?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`
        );
      } else {
        this.$router.push(
          `/${this.$i18n.locale}/payment-success-package?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`
        );
      }
    }
    if (this.$route.query.plant != "" && this.$route.query.plant != undefined) {
      this.payload.branch = parseInt(this.$route.query.plant);
      this.getPackage();
    }
    this.payload.booking_date = moment().format("YYYY-MM-DD");
    this.getPlant();
    this.getProfile();
    this.getGender();
    this.getIndentityType();
    this.getIndentityCountry();
    this.getRelationList();
    if (typeof this.$route.query.plant !== "undefined") {
      this.$store.state.plantPackage = this.$route.query.plant;
    }
  },
  methods: {
    setBranchBack() {
      this.totalPayment = 0;
      this.totalDiscount = 0;
      this.subTotal = 0;
      history.replaceState(null, null, `?plant=`);
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    openRegister() {
      this.resetOrderOther();
      this.openFormRegister = true;
      this.payload.family_id = "";
    },
    resetOrderOther() {
      this.payloadForOther = {
        idcard: "",
        firstname: "",
        lastname: "",
        birthDate: "",
        birthPlace: "",
        identityType: 128,
        identityCountry: 102,
        relation: "",
      };
    },
    async getRelationList() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/get-rel-type`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.relationItem = res.results;
      } catch (error) {
        console.log(error);
      }
    },
    async getIndentityType() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}identity-type/get?show_default=true`,
          {}
        );
        if (resp.statusCode == 200) {
          this.identityType = resp.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getIndentityCountry() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}country/get`,
          {}
        );
        if (resp.statusCode == 200) {
          this.identityCountry = resp.results.sort((a, b) =>
            this.sortByName(a, b)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGender() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}gender/get`,
          {}
        );

        if (resp.statusCode == 200) {
          this.gender = resp.results.sort((a, b) => this.sortByName(a, b));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPlant() {
      const resp = await API.get(`${process.env.VUE_APP_API_TRUST}plant/get`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      });
      if (resp.statusCode == 200) {
        this.branch = await resp.results.filter(item => {
          // dissable selain nrc bali
          // return item.type == "BLA" && (item.id == 12 || item.id == 4);
          return item.type == "VIRTU DIGILABA";
        });
      }
    },
    async getPackage() {
      try {
        if (
          this.payload.branch != "" &&
          this.payload.branch != undefined &&
          !isNaN(this.payload.branch) &&
          this.payload.branch != null
        ) {
          const resPackage = await API.get(
            `${process.env.VUE_APP_API_TRUST}package/get?plant_id=${this.payload.branch}`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          if (resPackage.statusCode === 200) {
            const getIndexPackage = resPackage.results.findIndex(
              dataPackage => dataPackage.id == this.$route.params.id
            );

            if (getIndexPackage >= 0) {
              this.servicePackage = resPackage.results[getIndexPackage];
              this.getDiscount();
              this.calculatePayment();
              this.show = true;
              this.dialogConfirmation = false;
              history.replaceState(
                null,
                null,
                `?plant=${
                  this.payload.branch == null ? "" : this.payload.branch
                }`
              );

              if (typeof this.$route.query.plant === "undefined") {
                this.$store.state.plantPackage = this.payload.branch;
              }
            } else {
              this.servicePackage = null;
              this.show = false;
              this.show = false;
              this.dialogConfirmation = true;
            }
          } else {
            this.servicePackage = null;
            this.show = false;
            this.show = false;
            this.dialogConfirmation = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getProfile() {
      try {
        const resp = await API.get(`${process.env.VUE_APP_API_TRUST}profile`, {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        });
        this.profile = {
          email: resp.person.personal_info.contacts.email,
          first_name: resp.person.first_name,
          last_name: resp.person.last_name,
          phone: resp.person.personal_info.contacts.mobile_phone_number,
        };
      } catch (error) {
        console.log(error);
      }
    },
    getInvoiceById(id) {
      if (typeof this.invoice !== "undefined" && this.invoice != null) {
        if (this.invoice.length > 0) {
          const index = this.invoice.findIndex(item => item.id == id);
          if (index >= 0) {
            return this.invoice[index];
          }
        }
      }
      return { final_price: 0, item_applied: 0, price_total: 0 };
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },

    async getDiscount() {
      this.listDiscount = null;
      let payloadDiscount = {
        ids: [this.servicePackage.id],
        //ids: [1],
        type: "package",
      };

      if (this.servicePackage != null) {
        var packageName = this.servicePackage.name
          .toLowerCase()
          .includes("mcu");
      }
      if (packageName && this.payload.branch != "") {
        payloadDiscount.plant_ids = [this.payload.branch];
      }

      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}discount/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          payloadDiscount
        );
        if (resp.statusCode == 200 && resp.results.length > 0) {
          this.listDiscount = resp.results.filter(item => {
            return item != null;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleVoucher(item) {
      this.vouchertrigger = this.activeVoucher;
      this.activeVoucher = [item];

      if (this.vouchertrigger[0] == this.activeVoucher[0]) {
        this.listDiscount.code = "";
        this.activeVoucher = [];
      }

      // if (!this.checkVoucherActive(item)) {
      //   this.activeVoucher.push(item);
      // } else {
      //   this.activeVoucher.splice(this.activeVoucher.indexOf(item), 1);
      // }
      this.calculatePayment();
    },
    // check voucher active
    checkVoucherActive(voucher) {
      return this.activeVoucher.some(item => item == voucher);
    },
    // calculate payment
    async calculatePayment() {
      if (this.$route.params.id) {
        const payload = {
          type: "package",
          items: [{ id: this.$route.params.id, qty: 1 }],
          vouchers: this.activeVoucher,
        };
        if (this.payload.branch != "") {
          payload.plant_id = this.payload.branch;
        }
        try {
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}discount/apply`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            payload
          );
          if (resp.statusCode == 200) {
            this.invoice = resp.results;
            this.totalPayment = 0;
            this.totalDiscount = 0;
            this.subTotal = 0;
            this.invoice.forEach(item => {
              this.totalPayment = this.totalPayment + item.final_price;
              this.totalDiscount = this.totalDiscount + item.discount_amount;
              this.subTotal = this.subTotal + item.price_total;
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // reset when 0
        this.invoice = null;
        this.totalPayment = 0;
        this.totalDiscount = 0;
        this.subTotal = 0;
      }
    },
    async register() {
      this.formRegisterHasErrors = false;
      Object.keys(this.formRegister).forEach(f => {
        if (!this.formRegister[f]) this.formRegisterHasErrors = true;
        this.$refs[f].validate(true);
      });
      const {
        firstname,
        lastname,
        idcard,
        birthDate,
        selectGender,
        identityType,
        identityCountry,
        relation,
      } = this.payloadForOther;
      const dataReady = {
        first_name: firstname,
        last_name: lastname,
        identity_number: idcard,
        identity_type: identityType,
        identity_country_issuer: identityCountry,
        date_of_birth: birthDate,
        gender: selectGender,
        relation: relation,
        client_unique_id: "tm617a34c4a3b86",
      };
      try {
        const resp = await await API.post(
          `${process.env.VUE_APP_API_TRUST}register/subaccount`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        if (resp.statusCode === 200) {
          this.$swal({
            text: "Add Sub Account success!",
            confirmButtonColor: "#1A428A",
            confirmButtonText: "Oke",
          }).then(result => {
            if (result.isConfirmed) {
              this.payloadForOther = resp.results.persona;
              this.payload.family_id = resp.results.persona.patient_id;
              this.getfamily();
            }
          });
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          text: "Error Registration!",
        });
      }
    },
    async payment() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach(f => {
        if (this.notForMe) {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
        } else {
          if (f != "family_id") {
            if (!this.form[f]) this.formHasErrors = true;
            this.$refs[f].validate(true);
          }
        }
      });
      if (!this.formHasErrors && this.invoice.length > 0) {
        let discount_amount = "";
        let voucher = "";
        this.show = false;
        if (this.invoice[0].voucher != null) {
          discount_amount = this.invoice[0].discount_amount;
          voucher = this.invoice[0].voucher;
        } else {
          discount_amount = null;
          voucher = null;
        }

        const dataReady = [
          {
            plant_id: this.payload.branch,
            booking_date: this.payload.booking_date,
            package_id: this.servicePackage.id,
            amount: this.servicePackage.prices[0].amount,
            discount_amount: discount_amount,
            voucher: voucher,
            notes: this.payload.note,
            description: this.payload.note ? this.payload.description : "-",
          },
        ];
        if (this.notForMe) {
          dataReady[0].patient_id = this.payload.family_id;
        }
        // submit reagis order
        try {
          const order = await API.post(
            `${process.env.VUE_APP_API_TRUST}registration/book`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          );
          if (order.statusCode === 200) {
            if (
              order.results.length > 0 &&
              order.results[0].midtrans_token != null
            ) {
              if (this.$store.state.midtransIsLoaded) {
                const vuethis = this;

                window.snap.pay(order.results[0].midtrans_token, {
                  onSuccess: function (result) {
                    vuethis.midtransStatus = result.statusCode;
                    vuethis.$router.push(
                      `/${vuethis.$i18n.locale}/payment-success-package?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}`
                    );
                  },
                  onPending: function (result) {
                    vuethis.midtransStatus = result.statusCode;
                    API.get(
                      `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${result.order_id}`,
                      {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      }
                    ).then(res => {
                      if (res.status_code == 200) {
                        vuethis.midtransStatus = res.status_code;
                        vuethis.$router.push(
                          `/${vuethis.$i18n.locale}/payment-success-package?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}`
                        );
                      } else {
                        // vuethis.$router.push(
                        //   `/${vuethis.$i18n.locale}/payment-pending-package?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}`
                        // );
                        vuethis
                          .$swal({
                            text: vuethis.$t("the_order_has_been_canceled"),
                            showCancelButton: false,
                            confirmButtonColor: "#f7685b",
                            confirmButtonText: vuethis.$t("close"),
                          })
                          .then(() => {
                            vuethis.$router.push(`/patient`);
                          });
                      }
                    });
                  },
                  onClose: function () {
                    vuethis
                      .$swal({
                        text: vuethis.$t("the_order_has_been_canceled"),
                        showCancelButton: false,
                        confirmButtonColor: "#f7685b",
                        confirmButtonText: vuethis.$t("close"),
                      })
                      .then(() => {
                        vuethis.$router.push(`/patient`);
                      });
                  },
                  onError: function (result) {
                    vuethis.midtransStatus = result.statusCode;
                    vuethis.$swal({
                      icon: "error",
                      text: "Error payment!",
                    });
                  },
                });
              }
            } else {
              this.midtransStatus = 0;
              this.$router.push(
                `/${this.$i18n.locale}/payment-success-package?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`
              );
            }
          } else if (order.statusCode == 403) {
            Object.keys(order.errors).forEach(element => {
              this.toast(
                "error",
                typeof order.errors[element] == "object"
                  ? order.errors[element][0]
                  : order.errors[element]
              );
            });
          } else {
            this.$swal({
              icon: "error",
              text: order.message,
            });
          }
        } catch (error) {
          this.$swal({
            icon: "error",
            text: this.$t("error_booking"),
          });
        }
      }
    },
    async resetCart() {
      // reset cart
      const cart = this.$store.state.cart.map(res => {
        let data = res;
        data.product_id = res.product.id;
        data.plant_id = res.plant.id;
        data.qty = 0;
        return data;
      });
      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}cart/store`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          cart
        );

        if (resp.statusCode == 200) {
          this.getCart();
          if (this.midtransStatus == 200) {
            this.$router.push(
              `/${this.$i18n.locale}/payment-success-package?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}`
            );
          } else if (this.midtransStatus == 0) {
            this.$router.push(`/dashboard/use-success`);
          } else {
            this.$router.push(`/patient`);
          }
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    async getfamily() {
      try {
        let resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/list`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.myFamily = resp.results.filter(res => res.relation != "-");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 40px !important;
}
</style>
>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.cart {
  .register-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: gray;
    span {
      color: $main_2;
      cursor: pointer;
    }
  }
  .count-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .billing-box {
    border-bottom: 1px solid #e0e0e0;
  }
  .checkbox {
    &--booking-else {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: gray;
      text-transform: none;
    }
  }
  .voucher {
    margin-bottom: 20px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .collapse--active {
    transform: rotate(180deg);
  }
  a {
    text-decoration: none;
  }
}
</style>
<style lang="scss">
.cart {
  .v-text-field {
    &.v-input--dense {
      input {
        font-size: 14px !important;
      }
    }
  }
  .v-select {
    &.v-input--dense {
      font-size: 14px !important;
    }
  }
}
</style>
